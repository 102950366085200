import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TypeOf } from "zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { loginSchema } from "../../services/form.service";
import {
  deleteUserService,
  loginService,
  profileService,
} from "../../services/auth.service";
import Error1Modal from "../../components/modals/Error1Modal";
import { modalStyle } from "../../consts/styles";
import { setItem } from "../../services/localStorage.service";

type LoginInput = TypeOf<typeof loginSchema>;

const MemberUnsubscribe = () => {
  const navigate = useNavigate();

  const [openSuccess, setSuccessOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  // process
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { register, formState, handleSubmit } = useForm<any>({
    resolver: zodResolver(loginSchema),
  });

  const { isValid, isDirty, errors } = formState;

  const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
    try {
      handleLoading();

      const loginResp = await loginService(values);

      const { access_token } = loginResp.data;

      const session = { access_token };

      setItem("session", JSON.stringify(session));

      const profileResp = await profileService();

      const { username, member_id } = profileResp.data;

      await deleteUserService({
        username,
        member_id,
      });

      handleSuccessOpen();

      setTimeout(() => {
        navigate("/login", {
          state: { logOut: true },
        });
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (message) handleErrorOpen();
  }, [message]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "100px",
        paddingTop: "35px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "17px",
        }}
      >
        <img src="/images/logo.png" alt="logo" width={75} height={100} />
      </div>

      <Container
        component="main"
        maxWidth={matches ? "md" : "sm"}
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            padding: "40px 30px",
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: 2,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box color="red" textAlign="center">
              ยกเลิกการเป็นสมาชิกจากระบบก้าวท้าใจ
            </Box>
            <Box color="red" textAlign="center">
              หากสมาขิกยืนยันการยกเลิกการเป็นสมาชิก ข้อมูลต่างๆ ของสมาชิก
              ระบบจะทำการลบข้อมูลต่างๆ ที่เกี่ยวข้อง ออกจากระบบ
              และสมาชิกจะไม่สามารถ login เข้าระบบได้อีก
              เว้นแต่จะทำการลงทะเบียนใหม่
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{
                gap: 2,
                display: "flex",
                flexDirection: "column",
                width: "280px",
              }}
            >
              <TextField
                color="lightGrey"
                fullWidth
                label="ชื่อผู้ใช้ หรือ เลขบัตรประชาชน"
                error={!!errors["username"]}
                {...register("username")}
              />

              <TextField
                color="lightGrey"
                fullWidth
                label="รหัสผ่าน"
                type="password"
                error={!!errors["password"]}
                {...register("password")}
              />

              <Button
                color="error"
                variant="contained"
                type="submit"
                disabled={!isDirty || !isValid || loading}
              >
                <Typography variant="subtitle2" color="re">
                  ยกเลิกการเป็นสมาชิก
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="ktgGreen.main"
            marginTop="15px"
          >
            ยกเลิกการเป็นสมาชิกสำเร็จ
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openError}>
        <Box sx={modalStyle} width={340}>
          <Error1Modal
            title={message}
            subtitle={``}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default MemberUnsubscribe;
