import { Box, Button, Container, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CommunityDescription from "../../components/user/dashboard/CommunityDescription";
import MemberCountChart from "../../components/user/dashboard/MemberCountChart";
import MemberDescription from "../../components/user/dashboard/MemberDescription";
import MemberProvince from "../../components/user/dashboard/MemberProvince";
import MemberSection from "../../components/user/dashboard/MemberSection";
import MemberTypeSection from "../../components/user/dashboard/MemberTypeSection";
import MemberZone from "../../components/user/dashboard/MemberZone";
import ReportExercise from "../../components/user/dashboard/ReportExercise";
import ResultDescription from "../../components/user/dashboard/ResultDescription";
import ResultReportExercise from "../../components/user/dashboard/ResultReportExercise";
import {
  memberSummary,
  exerciseSummary,
  getCommunitySummaryService,
} from "../../services/dashboard.service";
import { provinceService } from "../../services/master-data.service";
import DashboardKpi from "../../components/user/dashboard/DashboardKpi";
import { getItem } from "../../services/localStorage.service";

const Dashboard = () => {
  const [global] = useState<any>(getItem("global"));

  // 1 all, 2 member, 3 exercise
  const [queryType, setQueryType] = useState<string>("all");
  const [summary, setSummary] = useState<any>();
  const [summaryExercise, setExerciseSummary] = useState<any>();
  const [communitySummary, setCommunitySummary] = useState<any>();
  const [provinces, setProvinces] = useState([]);

  // services
  const getMemberSummary = async () => {
    const response = await memberSummary(queryType);
    setSummary(response.data);
  };

  const getExerciseSummary = async () => {
    const response = await exerciseSummary(queryType);
    setExerciseSummary(response.data);
  };

  const getCommunitySummary = async () => {
    const response = await getCommunitySummaryService(queryType);
    setCommunitySummary(response.data);
  };

  const getProvinces = async () => {
    const response = await provinceService();
    setProvinces(response.data);
  };

  useEffect(() => {
    if (["all", "season"].includes(queryType)) {
      getProvinces();
      getMemberSummary();
      getExerciseSummary();
      getCommunitySummary();
    } else {
    }
  }, [queryType]);

  return (
    <Box display="flex" justifyContent="center" py="50px">
      <Container
        component="main"
        maxWidth={false}
        sx={{ zIndex: 1, maxWidth: "1120px" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap="32px"
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            gap="8px"
            zIndex={1}
          >
            <Typography
              align="center"
              typography={{ xs: "h3", sm: "h1" }}
              color="ktgGreen.main"
            >
              ข้อมูล ณ วันที่ {dayjs().locale("th").format("D MMM BBBB")}
            </Typography>
            <Typography
              align="center"
              typography={{ xs: "h3", sm: "h1" }}
              color="softBlack.main"
            >
              เวลา {dayjs().format("HH:mm")} น.
            </Typography>
          </Box>

          <Box
            sx={{
              overflowY: { xs: "auto" },
              p: "4px",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box
              display="flex"
              sx={{
                width: "auto",
              }}
              justifyContent="center"
              gap="16px"
            >
              <Button
                color={queryType === "all" ? "blue" : "cwhite"}
                variant="contained"
                sx={{
                  width: { xs: "auto", sm: 1 },
                }}
                onClick={() => setQueryType("all")}
              >
                <Typography
                  variant="subtitle2"
                  color={queryType === "all" ? "white" : "grey"}
                >
                  ข้อมูลทั้งหมด
                </Typography>
              </Button>
              <Button
                color={queryType === "season" ? "blue" : "cwhite"}
                variant="contained"
                sx={{
                  width: { xs: "auto", sm: 1 },
                }}
                onClick={() => setQueryType("season")}
              >
                <Typography
                  variant="subtitle2"
                  color={queryType === "season" ? "white" : "grey"}
                >
                  ก้าวท้าใจ ปีงบ{" "}
                  {dayjs(global?.second_period_end_date)
                    .locale("th")
                    .format("BBBB")}
                </Typography>
              </Button>

              <Button
                color={queryType === "kpi" ? "blue" : "cwhite"}
                variant="contained"
                sx={{
                  width: { xs: "auto", sm: 1 },
                }}
                onClick={() => setQueryType("kpi")}
              >
                <Typography
                  variant="subtitle2"
                  color={queryType === "kpi" ? "white" : "grey"}
                >
                  รายงานตัวชี้วัด
                </Typography>
              </Button>
            </Box>
          </Box>

          {["all", "season"].includes(queryType) && (
            <>
              <MemberSection summary={summary} />
              <MemberTypeSection summary={summary} />
              <MemberCountChart weeklySummarie={summary?.weekly_summaries} />
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap="16px"
              >
                <MemberZone zoneSummaries={summary?.zone_summaries} />

                <MemberProvince
                  provinceSummaries={summary?.province_summaries}
                />
              </Box>
              <MemberDescription
                areaSummaries={summary?.area_summaries}
                provinces={provinces}
                queryType={queryType}
              />
              <ReportExercise
                allSummaries={summaryExercise?.all_summaries[0]}
              />
              <ResultReportExercise
                activityTypeSummaries={summaryExercise?.activity_type_summaries}
              />
              <ResultDescription
                areaSummaries={summaryExercise?.area_summaries}
                provinces={provinces}
                queryType={queryType}
              />
              <CommunityDescription
                typeSummaries={
                  communitySummary ? communitySummary?.type_summaries[0] : null
                }
                memberRanks={communitySummary?.member_ranks}
                experiencePointRanks={communitySummary?.experience_point_ranks}
              />
            </>
          )}

          {["kpi"].includes(queryType) && (
            <DashboardKpi provinces={provinces} />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Dashboard;
